<template>
  <template v-if="deviceType === 'desktop'">
    <div class="container-fluid fm-background-main-color" style="max-height: 100%">
      <Navbar v-bind:title="'FunBook'" v-bind:isChevron="false"></Navbar>
      <div class="row">
        <div class="col-5">
          <div class="row">
            <div class="col-6" v-on:click="getSubunits(1); currentlySelectedUnit = 'Klasa 1'">
              <Card
                v-bind:badge-text="'7 lat'"
                v-bind:title="'Klasa 1'"
                v-bind:progress="unitsProgress ? (unitsProgress[1] ? unitsProgress[1].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-1.jpg) 0 -9rem;'"
                v-bind:unit-id="1">
              </Card>
            </div>
            <div class="col-6" v-on:click="getSubunits(2); currentlySelectedUnit = 'Klasa 2'">
              <Card
                v-bind:badge-text="'8 lat'"
                v-bind:title="'Klasa 2'"
                v-bind:progress="unitsProgress ? (unitsProgress[2] ? unitsProgress[2].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-2.jpg) 0 16rem;'"
                v-bind:unit-id="2">
              </Card>
            </div>
          </div>

          <div class="row">
            <div class="col-6" v-on:click="getSubunits(3); currentlySelectedUnit = 'Klasa 3'">
              <Card
                v-bind:badge-text="'9 lat'"
                v-bind:title="'Klasa 3'"
                v-bind:progress="unitsProgress ? (unitsProgress[3] ? unitsProgress[3].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-3.jpg) 0 13rem;'"
                v-bind:unit-id="3">
              </Card>
            </div>
            <div class="col-6" v-on:click="getSubunits(4); currentlySelectedUnit = 'Klasa 4'">
              <Card
                v-bind:badge-text="'10 lat'"
                v-bind:title="'Klasa 4'"
                v-bind:progress="unitsProgress ? (unitsProgress[4] ? unitsProgress[4].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-4.jpg) 0 24rem;'"
                v-bind:unit-id="4">
              </Card>
            </div>
          </div>

          <div class="row">
            <div class="col-6" v-on:click="getSubunits(5); currentlySelectedUnit = 'Klasa 5'">
              <Card
                v-bind:badge-text="'11 lat'"
                v-bind:title="'Klasa 5'"
                v-bind:progress="unitsProgress ? (unitsProgress[5] ? unitsProgress[5].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-5.jpg) 0 13rem;'"
                v-bind:unit-id="5">
              </Card>
            </div>
            <div class="col-6" v-on:click="getSubunits(6); currentlySelectedUnit = 'Klasa 6'">
              <Card
                v-bind:badge-text="'12 lat'"
                v-bind:title="'Klasa 6'"
                v-bind:progress="unitsProgress ? (unitsProgress[6] ? unitsProgress[6].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-6.jpg) 0 12rem;'"
                v-bind:unit-id="6">
              </Card>
            </div>
          </div>

          <div class="row">
            <div class="col-6" v-on:click="getSubunits(7); currentlySelectedUnit = 'Klasa 7'">
              <Card
                v-bind:badge-text="'13 lat'"
                v-bind:title="'Klasa 7'"
                v-bind:progress="unitsProgress ? (unitsProgress[7] ? unitsProgress[7].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-7.jpg) 0 25rem;'"
                v-bind:unit-id="7">
              </Card>
            </div>
            <div class="col-6" v-on:click="getSubunits(8); currentlySelectedUnit = 'Klasa 8'">
              <Card
                v-bind:badge-text="'14 lat'"
                v-bind:title="'Klasa 8'"
                v-bind:progress="unitsProgress ? (unitsProgress[8] ? unitsProgress[8].progress : 0) : 0"
                v-bind:background-image="'url(../../../images/funbook/class-8.jpg) 0 19rem;'"
                v-bind:unit-id="8">
              </Card>
            </div>
          </div>
        </div>
        <div class="col-1"></div>
        <div class="col-4">
          <div id="accessInfo" class="alert alert-primary" role="alert" hidden>
            Otrzymałeś/aś {{ numberOfAccessDays }} dni dostępu.
          </div>
          <template v-if="inactiveAccessMessage">
            <ExpiredSubscription></ExpiredSubscription>
          </template>

          <template v-else>
            <div class="row fm-lesson-label-row">
              <div class="col-12 text-start">
                <span class="fm-lesson-label fm-label-main-color"> {{ currentlySelectedUnit}}</span>
              </div>
            </div>
            <ul class="list-group text-start fm-lessons-list">
              <template v-for="(category, index) in categories" v-bind:key="category.id">
                <li class="list-group-item fm-lessons-list-item" v-on:click="redirectToComponentsList(category.id)">
                  <div class="row">
                    <div class="col-10">{{ index + 1 }}. {{ category.name }}</div>
                    <div class="col-2">
                      <template v-if="subunitsProgress
                      && subunitsProgress.find(o => o.id === category.id)
                      && subunitsProgress.find(o => o.id === category.id).progress >= 50
                      && subunitsProgress.find(o => o.id === category.id).progress < 90">
                        <img class="fm-funbook-icon" src="../../public/images/icons/slightly-smiling-face.svg">
                      </template>
                      <template v-else-if="subunitsProgress
                      && subunitsProgress.find(o => o.id === category.id)
                      && subunitsProgress.find(o => o.id === category.id).progress > 90">
                        <img class="fm-funbook-icon" src="../../public/images/icons/smiling-face-with-sunglasses.svg">
                      </template>
                      <template v-else>
                        <img class="fm-funbook-icon" src="../../public/images/icons/sad-but-relieved-face.svg">
                      </template>
                    </div>
                  </div>
                </li>
              </template>
            </ul>
          </template>
        </div>
        <div class="col-2"></div>
      </div>

    </div>

  </template>

  <template v-if="deviceType === 'mobile'">
    <div class="container fm-background-main-color">

      <Navbar v-bind:title="'FunBook'" v-bind:isChevron="false"></Navbar>
      <div class="row">
        <div class="col-12">
          <div id="accessInfo" class="alert alert-primary" role="alert" hidden>
            Otrzymałeś/aś {{ numberOfAccessDays }} dni dostępu.
          </div>
        </div>
      </div>
      <template v-if="inactiveAccessMessage">
        <ExpiredSubscription></ExpiredSubscription>
      </template>
      <div class="row" style="margin-bottom: 8rem;">
        <div class="col-12"></div>
      </div>

      <Card v-on:click="redirectToLessonsList(1); currentlySelectedUnit = 'Klasa 1'"
        v-bind:badge-text="'7 lat'"
        v-bind:title="'Klasa 1'"
        v-bind:progress="unitsProgress ? (unitsProgress[1] ? unitsProgress[1].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-1.jpg) 0 -9rem;'"
        v-bind:unit-id="1">
      </Card>
      <Card v-on:click="redirectToLessonsList(2); currentlySelectedUnit = 'Klasa 2'"
        v-bind:badge-text="'8 lat'"
        v-bind:title="'Klasa 2'"
        v-bind:progress="unitsProgress ? (unitsProgress[2] ? unitsProgress[2].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-2.jpg) 0 16rem;'"
        v-bind:unit-id="2">
      </Card>
      <Card v-on:click="redirectToLessonsList(3); currentlySelectedUnit = 'Klasa 3'"
        v-bind:badge-text="'9 lat'"
        v-bind:title="'Klasa 3'"
        v-bind:progress="unitsProgress ? (unitsProgress[3] ? unitsProgress[3].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-3.jpg) 0 13rem;'"
        v-bind:unit-id="3">
      </Card>
      <Card v-on:click="redirectToLessonsList(4); currentlySelectedUnit = 'Klasa 4'"
        v-bind:badge-text="'10 lat'"
        v-bind:title="'Klasa 4'"
        v-bind:progress="unitsProgress ? (unitsProgress[4] ? unitsProgress[4].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-4.jpg) 0 24rem;'"
        v-bind:unit-id="4">
      </Card>
      <Card v-on:click="redirectToLessonsList(5); currentlySelectedUnit = 'Klasa 5'"
        v-bind:badge-text="'11 lat'"
        v-bind:title="'Klasa 5'"
        v-bind:progress="unitsProgress ? (unitsProgress[5] ? unitsProgress[5].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-5.jpg) 0 13rem;'"
        v-bind:unit-id="5">
      </Card>
      <Card v-on:click="redirectToLessonsList(6); currentlySelectedUnit = 'Klasa 6'"
        v-bind:badge-text="'12 lat'"
        v-bind:title="'Klasa 6'"
        v-bind:progress="unitsProgress ? (unitsProgress[6] ? unitsProgress[6].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-6.jpg) 0 12rem;'"
        v-bind:unit-id="6">
      </Card>
      <Card v-on:click="redirectToLessonsList(7); currentlySelectedUnit = 'Klasa 7'"
        v-bind:badge-text="'13 lat'"
        v-bind:title="'Klasa 7'"
        v-bind:progress="unitsProgress ? (unitsProgress[7] ? unitsProgress[7].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-7.jpg) 0 25rem;'"
        v-bind:unit-id="7">
      </Card>
      <Card v-on:click="redirectToLessonsList(8); currentlySelectedUnit = 'Klasa 8'"
        v-bind:badge-text="'14 lat'"
        v-bind:title="'Klasa 8'"
        v-bind:progress="unitsProgress ? (unitsProgress[8] ? unitsProgress[8].progress : 0) : 0"
        v-bind:background-image="'url(../../../images/funbook/class-8.jpg) 0 19rem;'"
        v-bind:unit-id="8">
      </Card>
      <div class="row text-end" style="margin-top: 32px; padding-right: -12px;">
        <div class="col-12 fm-funbook-squirrel">
          <img src="../../public/images/squirrel-with-grass.svg">
        </div>
      </div>

      <template v-for="component in components" v-bind:key="component.id">
        <p>{{ component.id }} {{ component.contentTypeName }}</p>
      </template>

    </div>
  </template>

</template>

<script>
import Navbar from '../components/Navbar';
import Card from '../components/FunBook/Card';
import CategoryService from '../services/categoryService';
import ExpiredSubscription from './ExpiredSubscription';
import ComponentService from '../services/componentService';

export default {
  name: 'FunBook',
  components: { ExpiredSubscription, Card, Navbar },
  data() {
    return {
      components: null,
      deviceType: this.setDeviceType(),
      categories: null,
      currentlySelectedUnit: 'Klasa 1',
      currentlySelectedUnitId: 1,
      inactiveAccessMessage: null,
      numberOfAccessDays: null,
      unitsProgress: null,
      subunitsProgress: null
    };
  },
  mounted() {
    window.addEventListener('resize', this.onResize);
    this.setDeviceType();

    this.getSubunits(1);
    this.getUnitsProgress();
    this.numberOfAccessDays = this.$route.query.access;

    if (this.numberOfAccessDays) {
      const accessInfo = document.getElementById('accessInfo');
      accessInfo.hidden = false;
      setTimeout(function() {
        accessInfo.hidden = true;
      }, 10000);

      this.$router.push('funbook');
    }
  },
  unmounted() {
    window.addEventListener('resize', this.onResize);
  },
  methods: {
    onResize() {
      this.setDeviceType();
    },
    setDeviceType() {
      if (window.outerWidth > 576) {
        this.deviceType = 'desktop';
      } else {
        this.deviceType = 'mobile';
      }

      return this.deviceType;
    },
    async getUnitsProgress() {
      const response = await ComponentService.getUnitsProgress();
      console.log('$$$$$$$$$$$$$$$$$$$');
      console.log(response.data);
      this.unitsProgress = response.data;
      console.log(this.unitsProgress);
    },
    async getSubunitsProgress(unitId) {
      const response = await ComponentService.getSubunitProgress(unitId);
      this.subunitsProgress = response.data;
    },
    async getSubunits(unitId) {
      const response = await CategoryService.getUnits(1, 1, false, unitId);
      this.categories = response.data.categories.items;

      if (this.categories === null) {
        const message = response.data.categories.message;

        if (message === 'User does not have active subscription') {
          this.inactiveAccessMessage = 'Użytkownik nie posiada wykupionego dostępu.';
        }
      }

      await this.getSubunitsProgress(unitId);
    },
    async redirectToComponentsList(unitId) {
      await this.$router.push({ name: 'Lesson', params: { id: unitId } });
    },
    async redirectToLessonsList(unitId) {
      await this.$router.push({ name: 'LessonList', params: { id: unitId } });
    }
  }
};
</script>

<style scoped>
.fm-funbook-squirrel {
  padding-right: 0;
}

.fm-lesson-label-row {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
}

.fm-lesson-label {
  font-size: 1.5rem;
  font-weight: bold;
}

.fm-lessons-list {
  padding-bottom: 4rem;
}

.fm-lessons-list-item {
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: pointer;
}

.fm-funbook-icon {
  height: 1.125rem;
}
</style>
