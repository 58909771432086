<template>
  <div
    v-bind:style="'background:' +
      'linear-gradient(0deg, rgba(173, 100, 52, 0.5), rgba(173, 100, 52, 0.5)),' +
      backgroundImage +
      'background-size: 22rem;'" class="card fm-funbook-card"
    type="button">
    <div class="card-body">
      <div class="text-end">
        <span class="badge text-end fm-badge">{{ badgeText }}</span>
      </div>
      <div class="text-start">
        <h5 class="card-title fm-card-title">{{ title }}</h5>
      </div>
      <div class="progress">
        <div
          class="progress-bar fm-progress-bar"
          role="progressbar"
          v-bind:style="{width: progress + '%'}"
          v-bind:aria-valuenow="progress"
          aria-valuemin="0"
          aria-valuemax="100">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Card',
  props: {
    badgeText: String,
    title: String,
    progress: Number,
    backgroundImage: String,
    unitId: Number
  }
};
</script>

<style scoped>
.fm-funbook-card {
  height: 10rem;
  margin-top: 1rem;
}

.fm-badge {
  font-size: 1rem;
  color: var(--dark-gunmetal);
  background-color: var(--pale-cyan);
}

.fm-card-title {
  color: white;
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 1.5rem;
}
</style>
